import axios from 'axios';
import config from '../config';

axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      config.withCredentials = true;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
class Auth {
    async checkIsAuthenticated  () {
        try {
            const response = await axios.get(`${config.serverHost}/checkIsAuthenticated`);
            return response.data.authenticated;
        } catch (error) {
            return error;
        }

    }
}

const authController = new Auth();
export default authController;