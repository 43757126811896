import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import auth from '../auth/index.js'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: async (to, from, next) => {
      if (!await auth.checkIsAuthenticated())
      return next('/login')
     return next();
    },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
   // beforeEnter: [checkIsAuthenticated.],
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    beforeEnter: async (to, from, next) => {
      if (!await auth.checkIsAuthenticated())
      return next()
     return next('/');
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
