<template>
  <v-table theme="dark">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Firstname
          </th>
          <th class="text-left">
            Lastname
          </th>
          <th class="text-left">
            Email
          </th>
          <th class="text-left">
            Verified
          </th>
          <th class="text-left">
            First Discount
          </th>
          <th class="text-left">
            Tuesday Discount
          </th>
          <th class="text-left">
            Updated Date
          </th>
          <th class="text-left">
            Created Date
          </th>
           <th class="text-left">
            <DownloadButton :datas="datas" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in datas"
          :key="item.name"
        >
          <td>{{ item.firstname }}</td>
          <td>{{ item.lastname }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.verified }}</td>
          <td>{{ item.discount_fisrt }}</td>
          <td>{{ item.discount_tuesday }}</td>
          <td>{{ new Date(item.updatedAt).toLocaleString() }}</td>
          <td>{{ new Date(item.createdAt).toLocaleString() }}</td>

        </tr>
      </tbody>
    </template>
  </v-table>
</template>

<script>
import DownloadButton from "@/components/DownloadButton.vue";

  export default {
    name: 'SimpleTable',
    props: ['datas'],
      components: {
    DownloadButton
  },
    data: () => ({
      desserts: [
        {name:'test', calories:'10g'}
      ]
    }),
  }
</script>
