import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import config from './config/index'
import VueExcelXlsx from "vue-excel-xlsx";

loadFonts()

const app = createApp(App);

app.config.globalProperties.serverHost = config.serverHost;


app
  .use(router)
  .use(VueExcelXlsx)
  .use(vuetify)
  .mount('#app')
