<template>
  <!--   <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
  </div> -->
  <v-app class="main">
    <v-app-bar theme="dark">
<!--       <router-link to="/">Home</router-link> 
      <router-link to="/about">About</router-link> -->
      MEYOU ADMIN
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped>
.main {
  background-color: rgb(42, 42, 38);
}
</style>
