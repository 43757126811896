<template>
  <vue-excel-xlsx
    :data="datas"
    :columns="columns"
    :file-name="'customersList'"
    :file-type="'xlsx'"
    :sheet-name="'sheetname'"
    class="buttonDownload"
  >
    Download
  </vue-excel-xlsx>
</template>

<script>
export default {
  name: "DownloadButton",
  components: {},
  props: ["datas"],
  methods: {
  },
  data: () => ({
    columns: [
      {
        label: "Firstname",
        field: "firstname",
      },
      {
        label: "Lastname",
        field: "lastname",
      },
      {
        label: "Email",
        field: "email",
      },
      {
        label: "Updated Date",
        field: "updatedAt",
        dataFormat: (value) => {
          return new Date(value).toLocaleString();
        },
      },
      {
        label: "Created Date",
        field: "createdAt",
        dataFormat: (value) => {
          return new Date(value).toLocaleString();
        },
      },
    ],
  }),
};
</script>

<style scoped>
.buttonDownload {
  border: 2px solid #faf320;
  color: #faf320;
  background-color: black;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
}
</style>
