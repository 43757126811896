<template>
  <div class="home">
    <SimpleTable :datas="data" />
  </div>
</template>

<script>
// @ is an alias to /src
import SimpleTable from "@/components/SimpleTable.vue";
import axios from 'axios';

export default {
  name: "home-view",
  components: {
    SimpleTable,
  },
  data: () => ({
    data: '',

  }),
  created() {
    axios.get(`${this.serverHost}/customers`).then(response => {
     //console.log(response)
      this.data = response.data;
    }).catch(() => {

    })
  }
};
</script>
